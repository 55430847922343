import { FlowEditorSDK, EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import type {
  WidgetInstallationType,
  addWidgetOptions,
} from '@wix/platform-editor-sdk';
import { BalanceWidgetData } from '../consts';
import { AppInstanceApi } from '../apis/AppInstanceApi';

const DEFAULT_WIDGET_LAYOUT = { height: 31, width: 235, x: 55, y: 55 };

const getWidgetOptions = (isRTL: boolean): addWidgetOptions => ({
  widgetId: BalanceWidgetData.ID,
  installationType: 'closed' as WidgetInstallationType,
  layout: DEFAULT_WIDGET_LAYOUT,
  scopedPresets: {
    desktop: {
      style: isRTL
        ? BalanceWidgetData.presets.desktop.rtl
        : BalanceWidgetData.presets.desktop.ltr,
      layout: isRTL
        ? BalanceWidgetData.presets.desktop.rtl
        : BalanceWidgetData.presets.desktop.ltr,
    },
    mobile: {
      style: isRTL
        ? BalanceWidgetData.presets.mobile.rtl
        : BalanceWidgetData.presets.mobile.ltr,
      layout: isRTL
        ? BalanceWidgetData.presets.mobile.rtl
        : BalanceWidgetData.presets.mobile.ltr,
    },
  },
  layouts: {
    componentLayout: {
      maxWidth: {
        type: 'percentage',
        value: 100,
      },
      hidden: false,
      height: {
        type: 'auto',
      },
      type: 'ComponentLayout',
      width: {
        type: 'maxContent',
      },
    },
    type: 'SingleLayoutData',
    itemLayout: {
      position: 'relative',
      id: '',
      alignSelf: 'center',
      gridArea: {
        rowStart: 1,
        rowEnd: 2,
        columnStart: 1,
        columnEnd: 2,
      },
      justifySelf: 'center',
      type: 'GridItemLayout',
    },
  },
});

export const addBalanceWidgetToStage = async (
  editorSDK: FlowEditorSDK,
  isRTL: boolean,
) => {
  await editorSDK.application.appStudioWidgets.addWidget('', {
    ...getWidgetOptions(isRTL),
  });
};

export const handleFirstInstall = async (
  flowAPI: EditorScriptFlowAPI,
  editorSDK: FlowEditorSDK,
  isRTL: boolean,
) => {
  try {
    addBalanceWidgetToStage(editorSDK, isRTL);
  } catch (e) {
    flowAPI.reportError(e as Error);
  }
};
