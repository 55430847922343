export const BalanceWidgetData = {
  ID: 'f9309f5d-6fd5-4e79-8269-ceb6f7ee6d76',
  Name: 'BalanceWidget',
  presets: {
    desktop: {
      ltr: 'variants-m653d5zx1',
      rtl: 'variants-m73b4ldt',
    },
    mobile: {
      ltr: 'variants-m709zsr9',
      rtl: 'variants-m73b7ff2',
    },
  },
};

export const SPECS = {
  RiseAppBalanceWidget: 'specs.stores.RiseAppBalanceWidget',
};

export const defaultBiMultiplier = 100000;
