import { EditorReadyFn } from '@wix/yoshi-flow-editor';
import { AppManifest } from '@wix/app-manifest-builder';
import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import { handleFirstInstall } from './editor-app/firstInstallHandler';
import { SPECS } from './consts';

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefId,
  options,
  flowAPI,
) => {
  const { experiments, reportError } = flowAPI;
  const isSiteRTL = flowAPI.environment.isSiteLanguageRTL;

  try {
    if (options.firstInstall) {
      experiments.enabled(SPECS.RiseAppBalanceWidget) &&
        (await handleFirstInstall(flowAPI, editorSDK, isSiteRTL));
    }
  } catch (e) {
    reportError(e as Error);
  }
};

// @ts-expect-error
export const getAppManifest = async (options): Promise<AppManifest> => {
  const { appManifestBuilder } = options;
  const baseManifest = await fetchWidgetsStageData(options);
  return appManifestBuilder.withJsonManifest(baseManifest).build();
};
