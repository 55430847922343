export enum WidgetBlocksIDs {
  Container = '#widget-container',
  Content = '#widget-content',
}

export enum WidgetRoles {
  Container = 'widget-container',
  Content = 'widget-content',
}

export enum WidgetGFPPActions {
  MAIN_ACTION = 'mainAction1',
  LAYOUT = 'layout',
  ADD = 'add',
  CONNECT = 'connect',
  LINK = 'link',
  UPGRADE = 'upgrade',
  STRETCH = 'stretch',
  ANIMATION = 'animation',
  DESIGN = 'design',
}

export const WidgetId = 'f9309f5d-6fd5-4e79-8269-ceb6f7ee6d76';
