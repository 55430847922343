
  var editorScriptEntry = require('/home/builduser/work/988aeed2e9be6ea4/packages/rise-app-site-plugin/src/editor.app.ts');

  
    if (editorScriptEntry.default) {
      module.exports = editorScriptEntry.default;
    } else {
      const { editorScriptBuilder } = require('@wix/bob-widget-services');
      const { editorReadyWrapper, createFlowAPIFabric, exportsWrapper } = require('@wix/yoshi-flow-editor/runtime/esm/editorScript.js');

      const biLogger = require('/home/builduser/work/988aeed2e9be6ea4/packages/rise-app-site-plugin/target/generated/bi/createBILogger.ts').createOwnerBILogger;

      

  const { initI18nWithoutICU: initI18n } = require('@wix/yoshi-flow-editor/runtime/esm/i18next/init');


      

    var createExperiments = null;
    var createWidgetExperiments = null;
    

      
const { createHttpClient } = require('@wix/yoshi-flow-editor/runtime/esm/controller/httpClientProp');
    


      const sentryConfig = {
        DSN: 'https://5463bad232df48319111715b8ebe67cf@sentry.wixpress.com/5895',
        id: 'undefined',
        projectName: 'rise-app-site-plugin',
        teamName: 'ecom-rise-app',
        errorMonitor: true,
      };
      const experimentsConfig = {"scopes":["test"],"centralized":true};
      const translationsConfig = {"icuEnabled":false,"defaultTranslationsPath":"/home/builduser/work/988aeed2e9be6ea4/packages/rise-app-site-plugin/src/assets/locales/messages_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hr","hu","id","it","ja","ko","lt","lv","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};
      const defaultTranslations = {"app.widget.welcome":"Hello","app.settings.label":"Configure Widget","app.settings.tabs.main":"Main","app.settings.tabs.design":"Design","app.settings.defaults.greetingsPretext":"to","checkoutSlot.redeemStoreCredit.codeAlreadyInUse.errorMessage":"This code is already in use.","checkoutSlot.redeemStoreCredit.cta":"Apply","checkoutSlot.redeemStoreCredit.upToOrderTotal.warningMessage":"The applied credit amount can be up to your order total.","checkoutSlot.redeemStoreCredit.creditEmpty.errorMessage":"You don’t have any available credit.","checkoutSlot.redeemStoreCredit.title":"Use your {amount} credit","checkoutSlot.redeemStoreCredit.title.new":"Use your {{amount}} credit","checkoutSlot.paymentDetails.enterStoreCredit.error.general":"We’re experiencing a technical issue on our end. Refresh the page and try again.","checkoutSlot.paymentDetails.enterStoreCredit.error.serviceDown":"Sorry, we can't accept credit right now because of a technical issue. Please continue without your credit or try again later.","checkoutSlot.paymentDetails.enterStoreCredit.error.disabled":"Your credit can't be applied right now. Please contact us for more details. ","checkoutSlot.paymentDetails.enterStoreCredit.error.noBalance":"You don’t have any available credit.","checkoutSlot.paymentDetails.enterStoreCredit.error.wrongCurrency":"The credit currency doesn't match the currency of the site. Change your cart's currency and try again.","checkoutSlot.paymentDetails.enterStoreCredit.error.expired":"Your credit has expired.","creditWidget.memberCredit.label.":"You have {amount} in credit","creditWidget.editWidget.gfpp.label":"Edit Credit Widget","creditWidget.memberCredit.label":"You have {{amount}} in credit","creditWidget.layersPanel.label":"Credit Balance Widget"};

      const createFlowAPI = createFlowAPIFabric({
        sentryConfig,
        experimentsConfig,
        translationsConfig,
        defaultTranslations,
        biLogger,
        shouldUseEssentials: true,
        artifactId: 'rise-app-site-plugin',
        appDefId: '56c4b3f5-a5f2-46bd-afb7-414136363b23',
        optionalDeps: {
          initI18n,
          createExperiments,
          createHttpClient
        },
        localeDistPath: 'assets/locales',
      });

      const useAPIsFromExports = typeof editorScriptEntry.exports === "function";

      function wrapEditorReady(eReady) {
        try {
          return editorReadyWrapper({
            createFlowAPI,
            editorReady: eReady,
            shouldSkipAPIOverrides: useAPIsFromExports,
          });
        } catch (e) {
          console.error(e);
          throw e;
        }
      }

      let wrappedEditorReady = wrapEditorReady(editorScriptEntry.editorReady);
      if (false) {
        const hot = require('@wix/yoshi-flow-editor/build/cjs/wrappers/editorScriptHot.js').hot;
        wrappedEditorReady = hot(module, wrappedEditorReady);
      }

      let builder = editorScriptBuilder();
      if (editorScriptEntry.editorReady) {
        builder = builder.withEditorReady(wrappedEditorReady);
      }

      if (editorScriptEntry.exports) {
        builder = builder.withExports(useAPIsFromExports ? exportsWrapper(editorScriptEntry.exports) : editorScriptEntry.exports);
      }

      if (editorScriptEntry.onEvent) {
        builder = builder.withEventHandler(editorScriptEntry.onEvent);
      }
      const controllerWidgetManifests = [];
      
      const userController_0 = require('/home/builduser/work/988aeed2e9be6ea4/packages/rise-app-site-plugin/src/components/BalanceWidget/editor.controller.ts');

      const model_0 = require('/home/builduser/work/988aeed2e9be6ea4/packages/rise-app-site-plugin/src/components/BalanceWidget/model.ts').default;

      const manifest_0 = userController_0 ? userController_0.default || userController_0 : {};
      if (!manifest_0.type) {
        manifest_0.type = "56c4b3f5-a5f2-46bd-afb7-414136363b23-itt7g";
      }
      if (!manifest_0.getExports && model_0) {
        manifest_0.getExports = () => ({"56c4b3f5-a5f2-46bd-afb7-414136363b23-itt7g": model_0.getExports()});
      }
      if (userController_0 && userController_0.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "56c4b3f5-a5f2-46bd-afb7-414136363b23-itt7g", getWidgetManifest: userController_0.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_0);

      if (editorScriptEntry.getAppManifest || controllerWidgetManifests.length) {
        const customGetAppManifest = async (...params) => {
          const [{ appManifestBuilder }, editorSDK, contextParams] = params;
          const flowAPI = await createFlowAPI(editorSDK, contextParams);
          controllerWidgetManifests.forEach(({ widgetId, getWidgetManifest}) => {
            appManifestBuilder.configureWidget(widgetId, (builder) => getWidgetManifest(builder, editorSDK, flowAPI));
          });

          if (editorScriptEntry.getAppManifest) {
            return editorScriptEntry.getAppManifest(...params, flowAPI);
          }

          return appManifestBuilder.build();
        };
        builder = builder.withAppManifest(customGetAppManifest);
      }
      var result = builder.build();
      module.exports = result;
  }
  
